import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../../../assests/svg/searchIcon.svg";
import {
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../../components/modals/InactiveActiveModal/InactiveActiveModal";
import AddEditOrgnaizationModal from "../../../../components/modals/addEditOrgnaization/addEditOrgnaizationModal";
import PageHeading from "../../../../components/pageHeading";
import ReactExcel from "../../../../components/reactExcel/ReactExcel";
import ReactTable from "../../../../components/reactTable/ReactTable";
import InputField from "../../../../components/theme/InputField";
import ReactSelectField from "../../../../components/theme/ReactSelectField";
import ThemeButton from "../../../../components/theme/ThemeButton";
import useFetch from "../../../../hooks/useFetch";
import {
  OrganizationItem,
  organizationAddEditType,
} from "../../../../types/organization";
import { addOrganizationSchema } from "../../../../validations/OrganizationSchema";
import styles from "./organizations.module.scss";
import { pause, unPause } from "../../../../assests/Icons/icon";
import moment from "moment";

// Options for Sort By and Filter Byimport Select from "react-select";

const filters = [
  { value: null, label: "All" },
  { value: 1, label: "Complete" },
  { value: 2, label: "Enabled" },
  { value: 3, label: "Error" },
  { value: 4, label: "Pending" },
  { value: 5, label: "InProgress" },
  { value: 0, label: "Not Setup" },
];

const initialValuesData = {
  organization_name: "",
  organization_identifier: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_code_id: 1,
  phone_number: "",
  partner: null,
  apiKey: "",
  theme_color: "#ffffff",
  font_color: "#000000",
  top_tipper_fees: null,
  logo_data: null,
};

const Organizations = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [editNameValue, setEditNameValue] = useState<string>("");
  // validation schema state
  const [validationSchema, setValidationSchema] = useState<any>();
  const [filterByStripeStatus, setFilterByStripeStatus] = useState<
    number | null
  >();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [organizationList, setOrganizationList] =
    useState<OrganizationItem[]>();
  const [orgExcelData, setOrgExcelData] = useState<OrganizationItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<OrganizationItem>();
  const [uuid, setUuid] = useState<string>();

  const [initialValues, setInitialValues] =
    useState<organizationAddEditType>(initialValuesData);

  /** Add edit modal*/
  const [editModal, setEditModal] = useState(false);
  const [show, setShow] = useState(false);
  const toggleAddEdit = () => setShow(!show);

  /** Active inactive modal*/
  const [showActive, setShowActive] = useState(false);

  const toggleActive = () => setShowActive(!showActive);

  /** Delete modal*/
  const [showDelete, setShowDelete] = useState(false);
  const toggleDelete = () => setShowDelete(!showDelete);

  const [pauseTipping, setPauseTipping] = useState(false);
  const togglePause = () => setPauseTipping(!pauseTipping);
  const handleTogglePause = () => setPauseTipping(false);

  /** Pagination */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  /** Api definition */
  const [
    listApi,
    { response: listResponse, loading: listLoading, error: listError },
  ] = useFetch("/organization/list", {
    method: "post",
  });
  /** Api definition */
  const [
    ExcelListApi,
    {
      response: ExcelListResponse,
      loading: ExcelListLoading,
      error: ExcelListError,
    },
  ] = useFetch("/organization/list", {
    method: "post",
  });

  const [addOrganizationApi, { response, loading, error }] = useFetch(
    "/organization/add",
    {
      method: "post",
    }
  );
  const [
    updateOrganizationApi,
    { response: updateResponse, loading: updateLoading, error: updateError },
  ] = useFetch(`/organization/update/${selectedItem?.uuid}`, {
    method: "put",
  });
  const [
    viewOrganizationApi,
    { response: viewResponse, loading: viewLoading, error: viewError },
  ] = useFetch(`/organization/view/${selectedItem?.uuid}`);
  const [
    statusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch(`/organization/active-inactive/${selectedItem?.uuid}`, {
    method: "post",
  });
  const [
    deleteApi,
    { response: deleteResponse, loading: deleteLoading, error: deleteError },
  ] = useFetch(`/organization/delete/${selectedItem?.uuid}`, {
    method: "delete",
  });
  const [
    pauseTippingApi,
    { response: pauseResponse, loading: pauseLoading, error: pauseError },
  ] = useFetch(`/organization/pause-resume-tips/${selectedItem?.uuid}`, {
    method: "post",
  });

  /** Call api */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
          stripe_status: filterByStripeStatus,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "listApi" });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPage, pageLimit, filterByStripeStatus]);

  /** Handle response */
  useEffect(() => {
    if (listResponse) {
      setTotalRecords(listResponse.data.totalRecords);
      const updateList = listResponse.data.list?.map((item: any) => {
        return {
          ...item,
          top_tipper_fees: item?.top_tipper_fees?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
            ? item?.top_tipper_fees?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
            : "--",
        };
      });
      setOrganizationList([...updateList]);
    }
    if (listError) {
      showToast(listError.message as string, "error");
    }
  }, [listResponse, listError]);

  useEffect(() => {
    if (response) {
      showToast(response.message as string, "success");
      toggleAddEdit();
      try {
        listApi({
          start: 0,
          limit: 10,
          search: "",
          stripe_status: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "listApi" });
      }
      setCurrentPage(1);
    }
    if (error) {
      showToast(error.message as string, "error");
    }
  }, [response, error]);
  useEffect(() => {
    if (updateResponse) {
      showToast(updateResponse.message as string, "success");
      toggleAddEdit();
      try {
        listApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
          stripe_status: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listApi",
        });
      }
    }
    if (updateError) {
      showToast(updateError.message as string, "error");
    }
  }, [updateResponse, updateError]);

  useEffect(() => {
    if (viewResponse) {
      const data = viewResponse.data;
      setEditNameValue(data.name);
      setInitialValues({
        organization_name: data.name,
        organization_identifier: data.url_code,
        first_name: data.user_details.first_name,
        last_name: data.user_details.last_name,
        email: data.user_details.email,
        phone_code_id: data.user_details.phone_code_details?.id,
        phone_number:
          data.user_details.phone_number != "null"
            ? data?.user_details?.phone_number
            : "",
        partner: data.partner_details?.id
          ? {
            value: data.partner_details?.id,
            label: data.partner_details?.name,
          }
          : null,
        apiKey: data.api_key?.trim(),
        theme_color: data.theme_color,
        font_color: data.font_color,
        top_tipper_fees: data.top_tipper_fees?.toFixed(2),
        logo_data: {
          id: data.organization_media_details?.id || null,
          name: data.organization_media_details?.name || "",
          url: data.organization_media_details?.url || "",
        },
      });
    }
    if (viewError) {
      showToast(viewError.message as string, "error");
    }
  }, [viewResponse, viewError]);

  useEffect(() => {
    if (statusResponse) {
      showToast(statusResponse.message as string, "success");
      toggleActive();
      const updatedList = organizationList?.map((item: OrganizationItem) =>
        selectedItem?.id === item.id
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setOrganizationList(updatedList);
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);

  useEffect(() => {
    if (deleteResponse) {
      showToast(deleteResponse.message as string, "success");
      toggleDelete();
      try {
        listApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: "",
          stripe_status: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listApi",
        });
      }
    }
    if (deleteError) {
      showToast(deleteError.message as string, "error");
    }
  }, [deleteResponse, deleteError]);

  useEffect(() => {
    if (pauseResponse) {
      showToast(pauseResponse.message as string, "success");
      togglePause();
      const updatedPauseList = organizationList?.map((item: OrganizationItem) =>
        selectedItem?.id === item.id
          ? { ...item, tip_status: item.tip_status ? 0 : 1 }
          : item
      );
      setOrganizationList(updatedPauseList);
    }
    if (pauseError) {
      showToast(pauseError.message as string, "error");
    }
  }, [pauseResponse, pauseError]);

  useEffect(() => {
    if (uuid) {
      viewOrganizationApi();
    }
  }, [uuid]);

  /**
   * @useEffect when current step updates
   */
  useEffect(() => {
    const validation = addOrganizationSchema(currentStep);
    setValidationSchema(validation);
  }, [currentStep]);

  // Function to handle closing the active confirmation modal
  const handleCloseActive = () => setShowActive(false);

  /**
   * @Array :- for table headings that passed as a prop to React-Table component
   */
  const tableHeadings = [
    // {
    //   key: "id",
    //   label: "Organization ID",
    //   onClick: (item: OrganizationItem) => {
    //     navigate(`/organizations/view/${item.uuid}`);
    //   },
    // },
    {
      key: "name",
      label: "Organization Name",
      onClick: (item: OrganizationItem) => {
        navigate(`/organizations/view/${item.uuid}`);
      },
    },
    {
      key: "stripe_status",
      label: "Stripe Status",
      activeColumn: true,
    },
    {
      key: "stripe_connect_account_id",
      label: "Stripe ID",
    },
    {
      key: "top_tipper_fees",
      label: "TopTipper Fee",
      textAlign: "right",
    },
  ];

  /**
   * @Array :- for action button dropdown values and passes to React table component
   */
  const actionButtonOptions = [
    {
      name: "View",
      icon: <IoEyeOutline />,
      onClick: (item: OrganizationItem) => {
        navigate(`/organizations/view/${item.uuid}`);
      },
    },
    {
      name: "Edit",
      icon: <MdEdit />,
      onClick: (item: OrganizationItem) => {
        setUuid(item.uuid);
        setCurrentStep(1);
        setSelectedItem(item);
        toggleAddEdit();
        setEditModal(true);
      },
    },
    {
      name: "Inactive",
      icon: <IoMdClose />,
      onClick: (item: OrganizationItem) => {
        toggleActive();
        setSelectedItem(item);
      },
    },
    {
      name: "Delete",
      icon: <RiDeleteBinLine />,
      onClick: (item: OrganizationItem) => {
        toggleDelete();
        setSelectedItem(item);
      },
    },
    {
      name: "Pause Tipping",
      icon: pause,
      onClick: (item: OrganizationItem) => {
        togglePause();
        setSelectedItem(item);
      },
    },
  ];

  const formik = useFormik<organizationAddEditType>({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (currentStep === 1) {
        setCurrentStep(2);
        formik.setFieldTouched("apiKey", false, false);
      } else if (currentStep === 2) {
        setCurrentStep(3);
        formik.setFieldTouched("theme_color", false, false);
        formik.setFieldTouched("font_color", false, false);
        formik.setFieldTouched("top_tipper_fees", false, false);
      } else {
        const apiData = {
          organization_name: values.organization_name,
          organization_identifier: editModal
            ? undefined
            : values.organization_identifier,
          first_name: values.first_name,
          last_name: values.last_name,
          email: editModal ? undefined : values.email,
          phone_code_id: 1,
          phone_number: `${values.phone_number}`,
          partner_id: values.partner?.value || null,
          api_key: values?.apiKey || "",
          theme_color: values.theme_color,
          font_color: values.font_color,
          top_tipper_fees: values.top_tipper_fees,
          media_id: values?.logo_data?.id || null,
          address: null,
          city: null,
          state: null,
          zip_code: null,
        };
        try {
          if (editModal) {
            await updateOrganizationApi(apiData);
            setCurrentStep(1);
            formik.resetForm();
            setUuid("");
          } else {
            await addOrganizationApi(apiData);
            setCurrentStep(1);
            formik.resetForm();
            setUuid("");
          }
        } catch (e: any) {
          notifyBugsnagError(e, {
            api: "updateOrganizationApi || addOrganizationApi",
          });
        }
      }
    },
  });

  const excelHeaders = [
    // { key: "id", header: "Organization ID", width: 20 },
    { key: "name", header: "Organization Name", width: 20 },
    { key: "is_active", header: "Status", width: 20 },
    {
      key: "stripe_status",
      header: "Stripe Status",
      width: 20,
    },
    {
      key: "stripe_connect_account_id",
      header: "Stripe ID",
      width: 30,
    },
    {
      key: "top_tipper_fees",
      header: "Top Tipper Fee",
      width: 30,
    },
    {
      header: "Qr Code",
      key: "qr_code",
      width: 85,
    },
    {
      header: "Link",
      key: "link",
      width: 85,
    },
    {
      header: "Creation Date",
      key: "createdAt",
      width: 30,
    },
    {
      header: "Last Login Date",
      key: "last_login",
      width: 30,
    },
    {
      header: "Last Tip Date",
      key: "last_tip_date",
      width: 30,
    },
  ];

  const handleExcelApi = () => {
    try {
      ExcelListApi({
        start: 0,
        limit: -1,
        search: searchValue,
        stripe_status: filterByStripeStatus,
      });
    } catch (e: any) {
      notifyBugsnagError(e, {
        api: "ExcelListApi",
      });
    }
  };

  /** Handle response */
  useEffect(() => {
    if (ExcelListResponse) {
      const updateOrgExcel = ExcelListResponse.data.list?.map(
        (item: OrganizationItem) => {
          return {
            stripe_status:
              item?.stripe_status === 1
                ? "Complete"
                : item?.stripe_status === 2
                  ? "Enabled"
                  : item?.stripe_status === 3
                    ? "Error"
                    : item?.stripe_status === 4
                      ? "Pending"
                      : item?.stripe_status === 5
                        ? "InProgress"
                        : "Not Setup",
            id: item?.id,
            name: item?.name ? item?.name : "--",
            is_active: item?.is_active ? "Active" : "In Active",
            stripe_connect_account_id: item?.stripe_connect_account_id
              ? item?.stripe_connect_account_id
              : "--",
            top_tipper_fees: item?.top_tipper_fees?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
              ? item?.top_tipper_fees?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
              : "--",

            qr_code: item?.qr_code ? item?.qr_code : "--",
            link: item?.copy_link ? item?.copy_link : "--",
            createdAt: item?.user_details?.createdAt ? moment(item?.user_details?.createdAt).format("MM/DD/YYYY") : '--',
            last_login: item?.user_details?.last_login ? moment(item?.user_details?.last_login).format("MM/DD/YYYY") : '--', last_tip_date: item?.transaction_details?.createdAt ? moment(item?.transaction_details?.createdAt).format("MM/DD/YYYY") : '--'

          };
        }
      );
      setOrgExcelData([...updateOrgExcel]);
    }
    if (ExcelListError) {
      showToast(ExcelListError.message as string, "error");
    }
  }, [ExcelListResponse, ExcelListError]);

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <PageHeading
                heading="Organizations"
                subHeading="Here is the information about all your organizations."
              >
                <div className={styles.search}>
                  <div className={styles.searchField}>
                    {/* <GoSearch className={styles.searchIcon} /> */}
                    <InputField
                      name="search_organization_name"
                      icon={searchIcon}
                      placeholder="Search by Organization Name"
                      value={searchValue}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSearchValue(e.target.value);
                      }}
                      autoComplete="off"
                    />
                    {searchValue ? (
                      <span
                        className={styles.crossIcon}
                        onClick={() => setSearchValue("")}
                      >
                        <RxCross1 />
                      </span>
                    ) : null}
                  </div>

                  <ThemeButton
                    onClick={() => {
                      toggleAddEdit();
                      setUuid("");
                      setEditModal(false);
                      setInitialValues(initialValuesData);
                    }}
                  >
                    {" "}
                    Add Organization
                  </ThemeButton>
                </div>
              </PageHeading>

              <div className={styles.tipsWrap}>
                <ReactSelectField
                  name="sortBy"
                  options={filters}
                  label="Filter By"
                  onChange={(newValue) => {
                    interface val {
                      value: number;
                    }
                    const { value } = newValue as val;
                    setCurrentPage(1);

                    setFilterByStripeStatus(value);
                  }}
                />
                <ReactExcel
                  setExcelData={setOrgExcelData}
                  loading={ExcelListLoading}
                  onClick={handleExcelApi}
                  buttonTitle="Export List"
                  fileType="xlsx"
                  fileName="Organization List"
                  coulmns={excelHeaders}
                  excelData={orgExcelData}
                />
              </div>
            </Col>

            <div className={styles.tableWrap}>
              <ReactTable
                headings={tableHeadings}
                data={organizationList || []}
                actionColumn
                statusColumn
                actionButtonOptions={actionButtonOptions}
                currentPage={currentPage}
                itemPerPage={pageLimit}
                totalItems={totalRecords}
                setPerPageLimit={setPageLimit}
                setCurrentPage={setCurrentPage}
                loading={listLoading || !organizationList}
                pagination={true}
              />
            </div>

            <ConfirmationModal
              handleToggle={toggleDelete}
              title="Are you sure you want to delete
                    this organization?"
              show={showDelete}
              confirm={() => deleteApi()}
              loading={deleteLoading}
            />

            <InactiveActiveModal
              loading={statusLoading}
              handleToggle={handleCloseActive}
              title={`Are you sure you want to ${selectedItem?.is_active ? "deactivate" : "activate"
                }
                    this organization?`}
              show={showActive}
              heading={selectedItem?.is_active ? "Deactivate" : "Activate"}
              confirm={() =>
                statusApi({
                  is_active: selectedItem?.is_active ? 0 : 1,
                })
              }
            />

            <InactiveActiveModal
              loading={pauseLoading}
              handleToggle={handleTogglePause}
              title={`Are you sure you want to ${selectedItem?.tip_status ? "Pause" : "Unpause"
                }
                    this Tipping?`}
              show={pauseTipping}
              heading={
                selectedItem?.tip_status ? "Pause Tipping" : "Unpause Tipping"
              }
              confirm={() =>
                pauseTippingApi({
                  tip_status: selectedItem?.tip_status ? 0 : 1,
                })
              }
            />

            <AddEditOrgnaizationModal
              editNameValue={editNameValue}
              show={show}
              handleClose={() => {
                toggleAddEdit();
                setUuid("");
                setEditNameValue("");
                formik.resetForm();
              }}
              title={editModal ? "Edit  Organization " : "Add Organization "}
              editModal={editModal}
              formik={formik}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              loading={loading || updateLoading}
              partnerDetails={viewResponse?.data?.partner_details}
              editLoading={viewLoading}
              setValidationSchema={setValidationSchema}
            />
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default Organizations;
